// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Router from "../../../libs/Router.res.js";
import * as Spinner from "../Spinner/Spinner.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ButtonScss from "./Button.scss";
import * as JsxRuntime from "react/jsx-runtime";

var css = ButtonScss;

function wrapperClassNameMaker(size, expanded, margin, className, param) {
  var tmp;
  switch (size) {
    case "XS" :
        tmp = css.sizeXs;
        break;
    case "SM" :
        tmp = css.sizeSm;
        break;
    case "MD" :
        tmp = css.sizeMd;
        break;
    case "LG" :
        tmp = css.sizeLg;
        break;
    
  }
  var tmp$1;
  tmp$1 = margin !== undefined ? (
      margin === "RightMargin" ? css.marginRight : css.marginLeft
    ) : "";
  return Cx.cx([
              css.wrapper,
              expanded ? css.expanded : "",
              tmp,
              tmp$1,
              className
            ]);
}

function buttonClassNameMaker(color, ghost, expanded, visuallyDisabled, buttonClassNameOpt, busy) {
  var buttonClassName = buttonClassNameOpt !== undefined ? buttonClassNameOpt : "";
  var tmp;
  switch (color) {
    case "Primary" :
        tmp = ghost ? css.colorGhostPrimary : css.colorPrimary;
        break;
    case "Teal" :
        tmp = ghost ? css.colorGhostTeal : css.colorTeal;
        break;
    case "Pink" :
        tmp = ghost ? css.colorGhostPink : css.colorPink;
        break;
    case "Gray" :
        tmp = css.colorGray;
        break;
    case "DarkerGray" :
        tmp = css.colorDarkerGray;
        break;
    case "Red" :
        tmp = ghost ? css.colorGhostRed : css.colorRed;
        break;
    case "White" :
        tmp = css.colorWhite;
        break;
    case "Clear" :
        tmp = ghost ? css.colorGhostClear : css.colorClear;
        break;
    case "RedDarker" :
        tmp = ghost ? css.colorGhostRedDarker : css.colorRedDarker;
        break;
    case "Orange" :
        tmp = ghost ? css.colorGhostOrange : css.colorOrange;
        break;
    
  }
  return Cx.cx([
              css.button,
              tmp,
              expanded ? css.expanded : "",
              visuallyDisabled ? css.visuallyDisabled : "",
              busy ? css.busy : "",
              buttonClassName
            ]);
}

function spinnerSize(size) {
  switch (size) {
    case "XS" :
        return "XS";
    case "SM" :
        return "SM";
    case "MD" :
        return "MD";
    case "LG" :
        return "LG";
    
  }
}

function spinnerColor(color, ghost) {
  switch (color) {
    case "Teal" :
        if (ghost) {
          return "Teal";
        } else {
          return "White";
        }
    case "Pink" :
        if (ghost) {
          return "Pink";
        } else {
          return "White";
        }
    case "Red" :
        if (ghost) {
          return "Red";
        } else {
          return "White";
        }
    case "RedDarker" :
        if (ghost) {
          return "RedDarker";
        } else {
          return "White";
        }
    case "Primary" :
    case "Orange" :
        if (ghost) {
          return "Orange";
        } else {
          return "White";
        }
    default:
      return "Black";
  }
}

function Button(props) {
  var iconSuffixComponent = props.iconSuffixComponent;
  var __buttonClassName = props.buttonClassName;
  var __className = props.className;
  var __submit = props.submit;
  var __busy = props.busy;
  var __visuallyDisabled = props.visuallyDisabled;
  var __disabled = props.disabled;
  var __expanded = props.expanded;
  var __ghost = props.ghost;
  var color = props.color;
  var size = props.size;
  var ghost = __ghost !== undefined ? __ghost : false;
  var expanded = __expanded !== undefined ? __expanded : false;
  var disabled = __disabled !== undefined ? __disabled : false;
  var visuallyDisabled = __visuallyDisabled !== undefined ? __visuallyDisabled : false;
  var busy = __busy !== undefined ? __busy : false;
  var submit = __submit !== undefined ? __submit : false;
  var className = __className !== undefined ? __className : "";
  var buttonClassName = __buttonClassName !== undefined ? __buttonClassName : "";
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("button", {
                      children: [
                        props.children,
                        iconSuffixComponent !== undefined ? JsxRuntime.jsx("div", {
                                children: Caml_option.valFromOption(iconSuffixComponent),
                                className: css.iconSuffixComponent
                              }) : null
                      ],
                      className: buttonClassNameMaker(color, ghost, expanded, visuallyDisabled, buttonClassName, busy),
                      disabled: disabled || visuallyDisabled,
                      type: submit ? "submit" : "button",
                      onClick: props.onClick
                    }),
                busy ? JsxRuntime.jsx(Spinner.make, {
                        size: spinnerSize(size),
                        color: spinnerColor(color, ghost),
                        centered: true
                      }) : null
              ],
              className: wrapperClassNameMaker(size, expanded, props.margin, className, undefined),
              id: props.id
            });
}

var css$1 = ButtonScss;

function Button$AsLink(props) {
  var __buttonClassName = props.buttonClassName;
  var __className = props.className;
  var __visuallyDisabled = props.visuallyDisabled;
  var __expanded = props.expanded;
  var __ghost = props.ghost;
  var ghost = __ghost !== undefined ? __ghost : false;
  var expanded = __expanded !== undefined ? __expanded : false;
  var visuallyDisabled = __visuallyDisabled !== undefined ? __visuallyDisabled : false;
  var className = __className !== undefined ? __className : "";
  var buttonClassName = __buttonClassName !== undefined ? __buttonClassName : "";
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(Router.Link.make, {
                    path: props.href,
                    className: buttonClassNameMaker(props.color, ghost, expanded, visuallyDisabled, buttonClassName, false),
                    onClick: props.onClick,
                    children: props.children
                  }),
              className: wrapperClassNameMaker(props.size, expanded, props.margin, className, undefined),
              id: props.id
            });
}

var AsLink = {
  css: css$1,
  make: Button$AsLink
};

var css$2 = ButtonScss;

function Button$AsNativeLink(props) {
  var __buttonClassName = props.buttonClassName;
  var __className = props.className;
  var __visuallyDisabled = props.visuallyDisabled;
  var __expanded = props.expanded;
  var __ghost = props.ghost;
  var targetBlank = props.targetBlank;
  var ghost = __ghost !== undefined ? __ghost : false;
  var expanded = __expanded !== undefined ? __expanded : false;
  var visuallyDisabled = __visuallyDisabled !== undefined ? __visuallyDisabled : false;
  var className = __className !== undefined ? __className : "";
  var buttonClassName = __buttonClassName !== undefined ? __buttonClassName : "";
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("a", {
                    children: props.children,
                    className: buttonClassNameMaker(props.color, ghost, expanded, visuallyDisabled, buttonClassName, false),
                    href: props.href,
                    rel: targetBlank !== undefined && targetBlank ? "noopener noreferrer" : undefined,
                    target: targetBlank !== undefined && targetBlank ? "_blank" : undefined,
                    onClick: props.onClick
                  }),
              className: wrapperClassNameMaker(props.size, expanded, props.margin, className, undefined)
            });
}

var AsNativeLink = {
  css: css$2,
  make: Button$AsNativeLink
};

var make = Button;

export {
  css ,
  wrapperClassNameMaker ,
  buttonClassNameMaker ,
  spinnerSize ,
  spinnerColor ,
  make ,
  AsLink ,
  AsNativeLink ,
}
/* css Not a pure module */
