// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Url from "../utils/Url.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as JsxRuntime from "react/jsx-runtime";

function handle(path, onClick, $$event) {
  Belt_Option.map(onClick, (function (fn) {
          return fn($$event);
        }));
  if (!$$event.defaultPrevented && $$event.button === 0 && !$$event.altKey && !$$event.ctrlKey && !$$event.metaKey && !$$event.shiftKey) {
    $$event.preventDefault();
    return Url.visit(path);
  }
  
}

function Router$Link(props) {
  var onClick = props.onClick;
  var path = props.path;
  return JsxRuntime.jsx("a", {
              children: props.children,
              className: props.className,
              href: path,
              onClick: (function (__x) {
                  handle(path, onClick, __x);
                })
            });
}

var Link = {
  handle: handle,
  make: Router$Link
};

export {
  Link ,
}
/* react/jsx-runtime Not a pure module */
